/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'calidation';
import uniq from 'lodash/uniq';
import CaptureAction from '@store/actions/capture';
import VoiFlowV2Action from '@store/actions/voiFlowV2';
import EditedAction from '@store/actions/edited';

import Page from '@lib/components/v2/Page';
import Modal from '@lib/components/v2/Modal';
import { hasAdressBeingEdited } from '@lib/utils/editedInfo';

import { getAge } from '@lib/Utils';
import { localizedString } from '@languages';
import APIs from '@services/APIs';

import { isAgeEligible } from '@lib/utils/ageEligibility';
import { isProofOfAgeDocument } from '@lib/constants/documents';
import {
  isDocumentExpired,
  isEligibleForPassportExpiredLogic,
  isExpiredIdAllowed,
  isPassportExpiredBy2Years
} from '@lib/utils/checkExpiredDocument';
import { AgeReview } from '@lib/components/v2/AgeReview';
import {
  ConfirmConsent,
  ConfirmGoBack,
  CouldNotRecognise,
  DocNotAccepted,
  EditTooManyFields,
  ExpiredID,
  ExpiredIDBy2Years,
  IdNumberNotEditable,
  PoorQuality,
  Recapture
} from './VerifyDetails.errors';
import { Error500 } from '../../errors';
import {
  CaptureTips,
  IdSelectionContent,
  MedicareNames,
  VerifyAdditionalDetailsContent,
  VerifyDetailsContent
} from '../../components/Contents';
import { defaultNameMatchDocs } from '../NameMatch';

const {
  ENABLE_ONE_DOC_CONDITION = false,
  VOI_FLOW_V2_NAME_MATCH_DOCS,
  VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN = false,
  VOI_FLOW_V2_HIDE_NAME_MATCH_SCREEN = false
} = process.env;

const nameMatchDocs = VOI_FLOW_V2_NAME_MATCH_DOCS || defaultNameMatchDocs;

class VerifyDetails extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    idType: PropTypes.string,
    token: PropTypes.string,
    location: PropTypes.string,
    verify: PropTypes.bool,
    flowType: PropTypes.string,
    selectedDocumentList: PropTypes.array,
    prevScreenState: PropTypes.any,
    setEditedFields: PropTypes.func,
    onRecapture: PropTypes.func,
    onExtracted: PropTypes.func,
    cardDetails: PropTypes.array,
    appConfig: PropTypes.object,
    setFrontIDParams: PropTypes.func,
    currentDocument: PropTypes.any,
    clearCardDetails: PropTypes.func,
    setCardDetails: PropTypes.func,
    onCaptureBack: PropTypes.func,
    onChooseDiffId: PropTypes.func
  };

  static defaultProps = {
    onNextStep: () => null,
    onGoBack: () => null,
    idType: 'NZL_DRIVERLICENCE',
    token: '',
    verify: false
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleRecapture = this.handleRecapture.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState(props) {
    const { editedFields } = props;
    const state = {
      data: {
        checkConfirm: false,
        dateOfBirth: '',
        expiryDate: ''
      },
      extracted: {},
      pageTitle: '',

      isUploading: true,
      progressBar: 0,

      disabled: true,
      confirmed: false,
      error: null,
      changes: editedFields,
      loading: false,
      isLoading: false,
      isWaiting: false,
      countryOfIssue: '',
      hasFormErrors: false,
      showTooManyEditsModal: false,
      currentDoc: {},
      token: '',
      showMedicareNames: false,
      medicareNames: {},
      showCaptureTips: '',
      extractAttempts: 0,
      useIdNumber: false
    };

    return state;
  }

  async componentDidMount() {
    const { selectedDocumentList, prevScreenState } = this.props;

    if (prevScreenState) {
      this.setState(prevScreenState);
      return;
    }

    const unextractedDocumentList = selectedDocumentList.filter((doc) => {
      return !doc.extracted;
    });

    if (unextractedDocumentList.length > 0) {
      const state = {
        currentDoc: unextractedDocumentList[0]
      };

      state.currentDoc.docIndex = selectedDocumentList.findIndex((doc2) => {
        return doc2.type === unextractedDocumentList[0].type;
      });

      this.setState(state);
      this.uploadImage(unextractedDocumentList[0]);
    } else {
      console.error('All the documents are processed');
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { prevScreenState } = this.state;
    if (nextProps.prevScreenState && prevScreenState !== nextProps.prevScreenState) {
      this.setState({
        prevScreenState: nextProps.prevScreenState
      });
    }
  }

  handleRecapture(card) {
    const { setEditedFields, onRecapture } = this.props;
    setEditedFields([]);
    onRecapture(card);
  }

  async handleConfirm({ isValid, dirty, fields }) {
    const {
      onExtracted,
      cardDetails,
      setFrontIDParams,
      clearCardDetails,
      setCardDetails,
      setEditedFields,
      selectedDocumentList,
      onGoBack,
      onNextStep
    } = this.props;
    const { token, currentDoc } = this.state;
    //--
    let isOtherCard = cardDetails.length > 0;
    const { location, flowType, appConfig } = this.props;
    const { data, hasFormErrors, changes } = this.state;

    if (!isValid) {
      this.setState({
        hasFormErrors: true
      });
      return;
    }

    if (data.documentId) {
      currentDoc.documentId = data.documentId;
    }
    this.setState({ currentDoc });
    onExtracted(currentDoc, token);

    const btnOk = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ error: null })
      }
    ];

    if (this.checkShowAddress() && !data.checkConfirm) {
      const error = {
        component: ConfirmConsent,
        props: {
          buttons: btnOk
        }
      };
      this.setState({
        error
      });
      return;
    }
    if (hasFormErrors) {
      return;
    }
    const { dateOfBirth, expiryDate, cardType, countryCode } = data;

    const { ENABLE_CONFIRM_AGE = true, ELIGIBLE_AGE = 18 } = process.env;
    const age = getAge(dateOfBirth, true);
    if (
      ENABLE_CONFIRM_AGE &&
      !isAgeEligible(age, ELIGIBLE_AGE) &&
      !data.cardType.match(/MEDICARE/i)
    ) {
      const error = {
        component: AgeReview,
        props: {
          eligibleAge: ELIGIBLE_AGE,
          onTryAgain: () => this.setState({ error: null })
        }
      };
      this.setState({ error });
      return;
    }

    const isPassportExpiredBy2YearsFlag = isPassportExpiredBy2Years(expiryDate, {
      appConfig,
      cardType,
      countryCode
    });
    const isDocumentExpiredFlag = isDocumentExpired(expiryDate);
    const isExpired = isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)
      ? isPassportExpiredBy2YearsFlag
      : isDocumentExpiredFlag;

    const error = {
      component: isPassportExpiredBy2YearsFlag ? ExpiredIDBy2Years : ExpiredID,
      props: {
        buttons: [
          {
            label: localizedString('back'),
            variant: 'transparent',
            onClick: () => {
              this.setState({ error: null });
            }
          },
          {
            label: localizedString('recaptureDocument'),
            onClick: () => {
              setFrontIDParams({});
              this.handleRecapture(currentDoc);
            }
          }
        ]
      }
    };

    if (!isExpiredIdAllowed(flowType) && isExpired) {
      this.setState({ error });
      return;
    }

    const { addressData } = data;

    this.setState({ loading: true });

    if (hasAdressBeingEdited({ dirty, fields })) {
      changes.push('addressData');
    }
    const finalData = {
      ...data,
      location,
      flowType,
      edited: [...changes],
      countryCode: data.countryCode,
      validateAddress: this.checkShowAddress()
    };
    if (addressData) {
      finalData.addressData = addressData;
    } else if (!addressData && flowType === 'VOI_FLOW_V2') {
      finalData.addressData = {
        fullAddress: 'NA'
      };
    }

    if (!finalData.cardType) {
      finalData.cardType = currentDoc.type;
    }

    let isOtherCardForQCondition = false;
    if (ENABLE_ONE_DOC_CONDITION) {
      const { currentDocument } = this.props;
      const { index } = currentDocument;

      if (index <= 0) {
        await clearCardDetails();
      } else if (index === 1) {
        isOtherCardForQCondition = true;
      }
    }

    setCardDetails(finalData);

    setEditedFields([]);

    const pendingIDs = selectedDocumentList.filter((doc) => {
      return !doc.extracted && doc.type !== currentDoc.type;
    });

    isOtherCard = cardDetails.length > 0 || isOtherCardForQCondition;
    if (pendingIDs.length === 0) {
      APIs.store(finalData, isOtherCard)
        .then(({ status, type, msg, nameMatched }) => {
          this.setState({ loading: false });
          if (status !== 'success') {
            if (type === 'cards') {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () => onGoBack()
                    }
                  ]
                }
              });
            } else {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () =>
                        this.setState({
                          error: null
                        })
                    }
                  ]
                }
              });
            }
            return;
          }

          let nextStep = { step: 2 };
          if (!nameMatched && !VOI_FLOW_V2_HIDE_NAME_MATCH_SCREEN) {
            nextStep = { showNameMatch: true, prevScreenState: this.state };
          } else if (!VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN && !ENABLE_ONE_DOC_CONDITION) {
            // It's OCR for Name Certs
            if (currentDoc.nextAfterOcr) {
              nextStep = currentDoc.nextAfterOcr;
            }
          } else {
            nextStep.secondDocument = this.determineSecondDocument(data);
          }

          onNextStep(nextStep);
        })
        .catch(({ message }) => {
          console.error(message);
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                this.handleRecapture(currentDoc);
              }
            }
          };
          this.setState({ error });
        });
    } else {
      APIs.store(finalData, isOtherCard)
        .then(({ status, type, msg }) => {
          this.setState({ loading: false });
          if (status !== 'success') {
            if (type === 'cards') {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () => onGoBack()
                    }
                  ]
                }
              });
            } else {
              this.setState({
                error: {
                  issue: msg,
                  buttons: [
                    {
                      label: localizedString('cancel'),
                      onClick: () =>
                        this.setState({
                          error: null
                        })
                    }
                  ]
                }
              });
            }
            return;
          }

          const state = this.getInitialState(this.props);

          const currentDoc = pendingIDs[0];
          currentDoc.docIndex = selectedDocumentList.findIndex((doc2) => {
            return doc2.type === pendingIDs[0].type;
          });
          this.setState({ ...state, currentDoc });
          this.uploadImage(pendingIDs[0]);
        })
        .catch(({ message }) => {
          console.error(message);
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                this.handleRecapture(currentDoc);
              }
            }
          };
          this.setState({ error });
        });
    }
  }

  /**
   * handle the checkbox button
   * @param {String} id
   * @param {Object} value
   * @param {Boolean} firstDoc
   * @return {Void}
   */
  async handleChange(id, value) {
    const { setEditedFields } = this.props;
    const { data, extracted, changes, currentDoc } = this.state;
    const { ID_NUMBER_EDITABLE = true, FIELD_EDIT_LIMIT_COUNT = 3 } = process.env;

    const editTooManyFieldsError = () => {
      const error = {
        component: EditTooManyFields,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              variant: 'transparent',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('recapture'),
              onClick: () => {
                this.handleRecapture(currentDoc);
              }
            }
          ]
        }
      };
      this.setState({ error });
    };

    // ID number not editable
    if (
      !ID_NUMBER_EDITABLE &&
      (id === 'idNumber' || id === 'licenceNumber' || id === 'passportNumber')
    ) {
      document.activeElement.blur(); // hide keyboard
      const error = {
        component: IdNumberNotEditable,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              variant: 'outline',
              onClick: () => this.setState({ error: null })
            },
            {
              label: localizedString('recapture'),
              onClick: () => {
                this.handleRecapture(currentDoc);
              }
            }
          ]
        }
      };
      this.setState({ error });
      return;
    }

    if (id !== 'addressData' && !id.match(/^check/)) {
      let filtered = [];
      if (value !== extracted[id]) {
        filtered = uniq([...changes, id]);
      } else {
        filtered = changes.filter((key) => {
          return key !== id;
        });
      }

      // Field Edit limit
      if (
        !nameMatchDocs.find((doc) => doc.type === currentDoc.type) &&
        filtered.length > FIELD_EDIT_LIMIT_COUNT
      ) {
        editTooManyFieldsError();
        return;
      }

      this.setState({ changes: filtered });
      setEditedFields(filtered);
    }

    if (['licenceNumber', 'passportNumber'].includes(id) && data.idNumber) {
      data.idNumber = value;
    }

    this.setState({
      data: {
        ...data,
        [id]: value
      }
    });
  }

  /**
   * Handle go back
   * @return {Void}
   */
  handleGoBack() {
    const { currentDoc } = this.state;
    const error = {
      component: ConfirmGoBack,
      props: {
        buttons: [
          {
            label: localizedString('cancel'),
            variant: 'outline',
            onClick: () => {
              this.setState({ error: null });
            }
          },
          {
            label: localizedString('yes'),
            onClick: () => {
              this.setState({ error: null });
              this.handleRecapture(currentDoc);
            }
          }
        ]
      }
    };
    this.setState({ error });
  }

  handleFormUpdate = ({ fields, isValid }) => {
    if (Object.keys(fields).length > 0) {
      this.setState({
        hasFormErrors: !isValid
      });
    }
  };

  onMedicareNameSelected = (newData) => {
    const { data } = this.state;
    this.setState({
      data: { ...data, ...newData },
      showMedicareNames: false
    });
  };

  onCaptureAgain() {
    const { currentDoc } = this.state;
    this.setState({ showCaptureTips: '' });
    this.handleRecapture(currentDoc);
  }

  checkNameDiff = () => {
    const { currentDoc } = this.state;
    this.props.onExtracted(currentDoc);

    const pendingIDs = this.props.selectedDocumentList.filter((doc) => {
      return !doc.extracted && doc.ocr;
    });

    if (pendingIDs.length === 0) {
      APIs.checkNameDiff()
        .then(({ nameMatched }) => {
          let nextStep = { step: 2 };
          if (!nameMatched) {
            nextStep = { showNameMatch: true, prevScreenState: this.state };
          }

          // It's OCR for Name Certs
          if (currentDoc.nextAfterOcr) {
            nextStep = currentDoc.nextAfterOcr;
          }

          this.props.onNextStep(nextStep);
        })
        .catch(({ message }) => {
          console.error(message);
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                this.handleRecapture(currentDoc);
              }
            }
          };
          this.setState({ error });
        });
    } else {
      const state = this.getInitialState(this.props);
      this.setState({ ...state, currentDoc: pendingIDs[0] });
      this.uploadImage(pendingIDs[0]);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  incrementRecaptureCount = (selectedDoc) => {
    if (!sessionStorage.getItem(`voi_v2_recapture_count_${selectedDoc.type}`)) {
      sessionStorage.setItem(`voi_v2_recapture_count_${selectedDoc.type}`, 1);
    } else {
      let count = sessionStorage.getItem(`voi_v2_recapture_count_${selectedDoc.type}`);
      count = parseInt(count, 10);
      sessionStorage.setItem(`voi_v2_recapture_count_${selectedDoc.type}`, count + 1);
    }
  };

  loadData = (selectedDoc, token) => {
    APIs.status('reviewInfo');

    const { verify } = this.props;
    const { type: idType, title } = selectedDoc;
    const hasNoBackCard = !('backOfCard' in selectedDoc);

    const { ENABLE_VISA_AFTER_PASSPORT = false } = process.env;

    this.setState({
      isLoading: true,
      pageTitle: `Are your ${title} details correct?`,
      currentDoc: selectedDoc
    });

    APIs.extractIdentifyInfo(token, hasNoBackCard, {
      isEngineV4: true,
      idType,
      documentId: selectedDoc.documentId
    })
      .then(async (res = {}) => {
        const {
          data: cardData,
          code,
          poorQuality = null,
          skip = false,
          docAccepted = true,
          captureBackCard = false,
          ignoreBackCardOfAUStates = ['NSW', 'TAS'],
          useIdNumber = false
        } = res;
        const { isLoading } = this.state;

        let { extractAttempts } = this.state;
        extractAttempts += 1;
        this.setState({ extractAttempts, useIdNumber });

        if (!isLoading) {
          return;
        }

        if (!VOI_FLOW_V2_HIDE_NAME_MATCH_SCREEN && skip) {
          // skip to next step
          this.checkNameDiff();
          return;
        }

        const buttons = [
          {
            label: localizedString('back'),
            variant: 'transparent',
            onClick: () => {
              this.setState({ error: null });
              this.props.onGoBack();
            },
            dataTestId: 'capture-backBtn'
          }
        ];

        if (code !== '200') {
          let count = sessionStorage.getItem(`voi_v2_recapture_count_${selectedDoc.type}`);
          count = parseInt(count, 10);
          if (count >= 1 && !VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN) {
            buttons.push({
              label: 'Choose another ID',
              onClick: () => {
                this.setState({ error: null, showCaptureTips: 'CouldntRecognise' });
                this.handleChooseDiffId();
                this.props.setEditedFields([]);
              }
            });
            const error = {
              // component: FaceNotDetectedInId,
              component: CouldNotRecognise,
              props: { buttons, idName: title }
            };

            this.setState({
              error,
              isLoading: false,
              progressBar: 0
            });

            return;
          }
        }

        if (docAccepted === false) {
          this.incrementRecaptureCount(selectedDoc);
          buttons.push({
            label: localizedString('RecaptureID'),
            onClick: () => {
              this.setState({ error: null });
              this.handleRecapture(selectedDoc);
            }
          });
          const error = {
            component: DocNotAccepted,
            props: { buttons }
          };
          this.setState({
            error,
            isLoading: false,
            progressBar: 0
          });
          return;
        }

        if (code === '220' || code === '230' || code === '240' || code === '270') {
          this.incrementRecaptureCount(selectedDoc);
          buttons.push({
            label: localizedString('capture.FLOW_V2_VIEW_TIPS_BUTTON'),
            onClick: () => {
              this.props.setFrontIDParams({});
              this.setState({ error: null, showCaptureTips: 'CouldntRecognise' });
            }
          });
          const error = {
            component: CouldNotRecognise,
            props: { buttons, idName: title }
          };
          this.setState({
            error,
            isLoading: false,
            progressBar: 0
          });
          return;
        }

        if (code !== '200') {
          this.incrementRecaptureCount(selectedDoc);
          buttons.push({
            label: localizedString('capture.FLOW_V2_VIEW_TIPS_BUTTON'),
            onClick: () => {
              this.props.setFrontIDParams({});
              this.setState({ error: null, showCaptureTips: 'CouldntRecognise' });
            }
          });
          const error = {
            component: CouldNotRecognise,
            props: { buttons, idName: title }
          };
          this.setState({
            error,
            isLoading: false,
            progressBar: 0
          });
          return;
        }

        if (poorQuality && extractAttempts === 1) {
          this.incrementRecaptureCount(selectedDoc);
          buttons.push({
            label: localizedString('capture.FLOW_V2_VIEW_TIPS_BUTTON'),
            onClick: () => {
              this.props.setFrontIDParams({});
              this.setState({ error: null, showCaptureTips: 'PoorQuality' });
            },
            dataTestId: 'betterImg-viewTipsBtn'
          });
          const error = {
            component: PoorQuality,
            props: { buttons, cropped: poorQuality, idName: title }
          };
          this.setState({
            error,
            isLoading: false,
            progressBar: 0
          });
          return;
        }
        const country = cardData.countryCode;

        if (
          (cardData.cardType === 'Passport' || cardData.cardType === 'MRZ') &&
          ENABLE_VISA_AFTER_PASSPORT &&
          country &&
          country.toUpperCase() !== 'NZ' &&
          country.toUpperCase() !== 'AU'
        ) {
          this.setState({ isLoading: false, progressBar: 0 });
          return;
        }

        if (!selectedDoc.params.backFile && captureBackCard) {
          if (
            cardData.cardType.match(/LICENCE/i) &&
            country === 'AU' &&
            ignoreBackCardOfAUStates.includes(cardData.state)
          ) {
            // Skip
          } else if (cardData.cardType.match(/LICENCE/i) && country === 'UK') {
            // Skip
          } else {
            this.props.onCaptureBack(selectedDoc);
            return;
          }
        }

        sessionStorage.removeItem(`voi_v2_recapture_count_${selectedDoc.type}`);

        const temp = cardData;
        // validate dates
        // temp['dateOfBirth'] = validateDate(temp['dateOfBirth']) ? temp['dateOfBirth'] : ''
        // temp['expiryDate'] = validateDate(temp['expiryDate']) ? temp['expiryDate'] : ''

        // format dates
        if (!cardData.cardType.match(/MEDICARE/i)) {
          temp.dateOfBirth = this.formatDay(temp.dateOfBirth);
          temp.expiryDate = this.formatDay(temp.expiryDate);
        }

        temp.countryOfIssue = cardData.country;

        let nameCount = 0;
        if (cardData.names && cardData.cardType.match(/MEDICARE/i)) {
          nameCount = Object.keys(cardData.names).length;
        }

        if (nameCount > 0) {
          if (nameCount > 1) {
            this.setState({
              showMedicareNames: true,
              medicareNames: cardData.names
            });
          } else {
            // Only one name
            const nameKey = Object.keys(cardData.names)[0];
            const nameValue = cardData.names[nameKey];
            const nameString = `${nameKey} ${nameValue}`;

            const parts = nameString.split(/\s(.+)/);

            [temp.medicareRefNumber, temp.fullName] = parts;
          }

          delete temp.names;
        }

        this.setState({ data: temp, extracted: res.data });

        if (!verify) {
          this.setState({
            isLoading: false
          });
        } else {
          this.setState({
            isLoading: false,
            isWaiting: true
          });

          APIs.status('checkDocument').then(() => {
            APIs.checkApproval().then((status) => {
              const { isWaiting } = this.state;
              if (!isWaiting) {
                return;
              }

              this.setState({ isWaiting: false });
              if (status !== 'approved') {
                const error = {
                  component: Recapture,
                  props: {
                    buttons: [
                      {
                        label: localizedString('recapture'),
                        onClick: () => {
                          this.props.setFrontIDParams({});
                          this.handleNextStep();
                        }
                      }
                    ]
                  }
                };
                this.setState({ error, isLoading: false });
              }
              // this.props.onNextStep({ tokenId: token, idType, backOfCard, verifyDetails: temp });
            });
          });
        }
      })
      .catch((e) => {
        console.error(e);
        let count = sessionStorage.getItem(`voi_v2_recapture_count_${selectedDoc.type}`);
        count = parseInt(count, 10);
        if (count >= 1 && !VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN) {
          this.handleChooseDiffId();
        } else {
          this.incrementRecaptureCount(selectedDoc);
        }
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              if (count >= 1 && !VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN) {
                this.handleChooseDiffId();
                this.props.setEditedFields([]);
              } else {
                this.setState({ error: null });
                this.handleRecapture(selectedDoc);
              }
            }
          }
        };
        this.setState({ error });
      });
  };

  // eslint-disable-next-line class-methods-use-this
  formatDay = (value) => {
    if (!value || value === '') {
      return '';
    }

    let parts;
    if (value.includes('-')) {
      parts = value.split('-');
    } else {
      parts = value.split('/');
    }

    const [day, month, year] = parts;
    return `${day}-${month}-${year}`;
  };

  handleChooseDiffId = () => {
    if (!VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN) {
      const { currentDoc } = this.state;
      this.props.onChooseDiffId(currentDoc);
    }
  };

  uploadImage(selected) {
    const { selectedDocumentList = [], onNextStep, onExtracted } = this.props;
    const selectedIndex = selectedDocumentList.findIndex((card) => {
      return card.type === selected.type;
    });

    APIs.status('capturingId', { resetTxnData: selectedIndex === 0 });

    const { title, params } = selected;
    this.setState({
      isUploading: true,
      progressBar: 0,
      isLoading: true,
      pageTitle: `Are your ${title} details are correct?`
    });

    APIs.uploadImage(
      { ...params, idType: selected.type },
      {
        before: () => this.setState({ progressBar: 0 }),
        onProgress: (width) => this.setState({ progressBar: width })
      },
      '/api/v4'
    )
      .then(async ({ status, token, msg }) => {
        const { isUploading } = this.state;
        if (!isUploading) {
          return;
        }

        if (status === 'error') {
          console.error('error', msg);

          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                this.setState({ error: null });
                this.handleRecapture(selected);
              }
            }
          };

          this.setState({
            error,
            isUploading: false,
            progressBar: 0
          });

          return;
        }

        this.setState({ isUploading: false, token });

        if (selected.ocr) {
          this.loadData(selected, token);
        } else {
          await onExtracted(selected);
          const { selectedDocumentList = [] } = this.props;
          const pendingIDs = selectedDocumentList.filter((doc) => {
            return !doc.extracted;
          });

          if (pendingIDs.length === 0) {
            // OCR is False
            let nextStep = { step: 2 };

            if (selected.nextAfterOcr) {
              nextStep = selected.nextAfterOcr;
            }
            onNextStep(nextStep);
          } else {
            const state = this.getInitialState(this.props);
            if (
              ['AUS_AUTO_BIRTH', 'AUS_AUTO_NAME', 'AUS_AUTO_MARRIAGE'].includes(pendingIDs[0].type)
            ) {
              state.showStateSelection = true;
              this.setState({ ...state, currentDoc: pendingIDs[0] });
            } else {
              this.setState({ ...state, currentDoc: pendingIDs[0] });
              this.uploadImage(pendingIDs[0]);
            }
          }
        }
      })
      .catch((e) => {
        console.error(e);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              this.setState({ error: null });
              this.handleRecapture(selected);
            }
          }
        };

        this.setState({ error });
      });
  }

  determineSecondDocument(data) {
    let result = false;

    const { currentDocument } = this.props;

    if (currentDocument.index < 1) {
      const { conditions, state, cardType } = data;

      if (
        conditions &&
        conditions.includes('Q') &&
        state === 'NSW' &&
        cardType === 'Driver Licence'
      ) {
        result = true;
      } else if (cardType === 'Driver Licence' && state !== 'NSW') {
        result = true;
      } else if (isProofOfAgeDocument(cardType)) {
        // This is Proof Of Age card
        result = true;
      }
    }

    return result;
  }

  checkShowAddress() {
    const { selectedDocumentList } = this.props;
    if (ENABLE_ONE_DOC_CONDITION) {
      const { currentDocument } = this.props;
      const { index } = currentDocument;

      if (index > 0) {
        return false;
      }
      return true;
    }

    const isDLSelected = selectedDocumentList.some((card) => {
      return card.type.match(/LICENCE/i);
    });

    const firsDoc = selectedDocumentList[0];

    const { currentDoc } = this.state;

    if (!currentDoc.type || !currentDoc.type) {
      return false;
    }

    return isDLSelected ? currentDoc.type.match(/LICENCE/i) : firsDoc.type === currentDoc.type;
  }

  renderTooManyEditsModal = () => {
    const { showTooManyEditsModal } = this.state;
    const confirmBtns = [
      {
        label: localizedString('tryAgain'),
        full: true,
        onClick: () => {
          this.setState({
            showTooManyEditsModal: false
          });
          this.props.setFrontIDParams({});
          this.props.onGoBack();
        }
      }
    ];

    return (
      <Modal
        isOpen={showTooManyEditsModal}
        heading={localizedString('takeAnotherPhoto')}
        buttons={confirmBtns}
      >
        {localizedString('takeAnotherPhotoDesc1')}
        <br />
        <br />
        {localizedString('takeAnotherPhotoDesc2')}
      </Modal>
    );
  };

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    let { pageTitle } = this.state;
    const {
      error,
      data,
      loading,
      isUploading,
      progressBar,
      isLoading,
      countryOfIssue,
      hasFormErrors,
      currentDoc,
      showMedicareNames,
      medicareNames,
      showCaptureTips = '',
      useIdNumber
    } = this.state;

    const { countryCode = '' } = data || {};

    const { component: Error, props: errorProps } = error || {};
    let { idType } = this.props;
    const { flowType } = this.props;
    const isSubmiting = loading || isUploading || isLoading;

    // Patch
    if (data.cardType) {
      idType = data.cardType;
    }

    const footerButtons = [
      {
        label: localizedString('back'),
        disabled: isSubmiting,
        variant: 'transparent',
        onClick: this.handleGoBack
      },
      {
        label: isSubmiting
          ? localizedString('loading')
          : localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_ACTION_BUTTON'),
        type: 'submit',
        disabled: (this.checkShowAddress() && !data.checkConfirm) || hasFormErrors,
        variant: isSubmiting ? 'transparent' : 'secandary',
        loading: isSubmiting
      }
    ];

    if (ENABLE_ONE_DOC_CONDITION) {
      if (footerButtons.length >= 2) {
        footerButtons[1].label = 'Continue';
      }
      pageTitle = 'Check your I.D details';
    }

    return (
      <div>
        {Error && <Error {...errorProps} />}
        {showCaptureTips !== '' && (
          <CaptureTips
            type={showCaptureTips}
            onHide={() => this.onCaptureAgain()}
            onCaptureAgain={() => this.onCaptureAgain()}
          />
        )}
        {showMedicareNames && showCaptureTips === '' && (
          <MedicareNames
            onSelected={this.onMedicareNameSelected}
            onGoBack={this.handleGoBack}
            names={medicareNames}
          />
        )}
        {!showMedicareNames && showCaptureTips === '' && (
          <Form onUpdate={this.handleFormUpdate} onSubmit={this.handleConfirm}>
            <Page buttons={footerButtons} footerShadow forceFillViewPort={isLoading}>
              {isLoading && (
                <IdSelectionContent selectedCard={currentDoc} progressBar={progressBar} isLoading />
              )}
              {!isLoading && this.checkShowAddress() && (
                <VerifyDetailsContent
                  {...data}
                  idType={idType}
                  idTypeTitle={currentDoc.title}
                  cardType={data.cardType}
                  flowType={flowType}
                  pageTitle={pageTitle}
                  countryOfIssue={countryOfIssue || data.countryOfIssue}
                  country={countryCode}
                  useIdNumber={useIdNumber}
                  onChange={this.handleChange}
                  isSubmiting={isSubmiting}
                />
              )}
              {!isLoading && !this.checkShowAddress() && (
                <VerifyAdditionalDetailsContent
                  data={data}
                  idType={idType}
                  idTypeTitle={currentDoc.title}
                  flowType={flowType}
                  pageTitle={pageTitle}
                  useIdNumber={useIdNumber}
                  onChange={this.handleChange}
                  isSubmiting={isSubmiting}
                />
              )}
              {this.renderTooManyEditsModal()}
            </Page>
          </Form>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDetails);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps(state) {
  const { capture, appConfig, voiFlowV2, edited } = state;
  return {
    frontParams: capture.frontParams,
    appConfig,
    cardDetails: voiFlowV2.cardDetails,
    editedFields: edited.fields
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    clearCardDetails: () => dispatch(VoiFlowV2Action.clearCardDetails()),
    setCardDetails: (data) => dispatch(VoiFlowV2Action.setCardDetails(data)),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data)),
    setEditedFields: (data) => dispatch(EditedAction.setEditedFields(data))
  };
}
